import React, { useState, useEffect, useContext } from 'react';
import { Amplify, Auth, Storage, Hub } from 'aws-amplify';
import { HubCallback, Credentials } from '@aws-amplify/core';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Calendar from 'dayjs/plugin/calendar';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { MabelUser, MabelUserRole, UserContext } from './contexts/UserContext';

import { useSessionStorageState, useCountDown } from 'ahooks';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import { Layout, notification, Result, Modal } from 'antd';

import { ApolloProvider, useApolloClient } from '@apollo/react-hooks';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { cacheIdFromObject } from './graphql/utils';

import {
  useUpsertUserMutation,
  UserBasicFragment,
  Users_Update_Column,
} from './graphql/generated';

import './App.less';
import LoginPage from './pages/LoginPage';
import TemplatesAdminPage from './pages/TemplatesAdminPage';
import CampaignsAdminPage from './pages/CampaignsAdminPage';
import NetworksAdminPage from './pages/NetworksAdminPage';
import PracticesAdminPage from './pages/PracticesAdminPage';
import ProvidersAdminPage from './pages/ProvidersAdminPage';
import InsuranceSegmentMappingPage from './pages/InsuranceSegmentMappingPage';
import SettingsPage from './pages/SettingsPage';

import PracticeHomePage from './pages/PracticeHomePage';
import OnboardingPage from './pages/OnboardingPage';
import PatientsPage from './pages/PatientsPage';
import ProvidersPage from './pages/ProvidersPage';
import AlignmentProcessingPage from './pages/AlignmentProcessingPage';
import HEDRProcessingPage from './pages/HEDRProcessingPage';
import PatientsAdminPage from './pages/PatientsAdminPage';
import CampaignsPage from './pages/CampaignsPage';
import CampaignManagerPage from './pages/CampaignManagerPage';
import PracticeAnalyticsPage from './pages/PracticeAnalyticsPage';
import OrganizationHomePage from './pages/OrganizationHomePage';
import OrganizationsAdminPage from './pages/OrganizationsAdminPage';

import UsersAdminPage from './pages/UsersAdminPage';
import MabelNavBar from './components/MabelNavBar';
import useWindowDimensions from './hooks/useWindowDimensions';
import usePageTracking from './hooks/usePageTracking';

import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/browser';
import { HelmetProvider } from 'react-helmet-async';
import { useIdleTimer } from 'react-idle-timer';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './MSauthConfig';

import PdfSplitterPage from './pages/PdfSplitterPage';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://0447a7f374604b6bbc41400ea1392911@o261006.ingest.sentry.io/5280645',
    environment: process.env.REACT_APP_SENTRY_ENV,
    // apparently the ResizeObserver error is benign:
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

const msalInstance = new PublicClientApplication(msalConfig);
const aws_config = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  aws_user_files_s3_bucket: process.env.REACT_APP_PRIVATE_STORAGE_BUCKET,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_AWS_REGION,
};
Amplify.configure(aws_config);
Auth.configure(aws_config);
// Storage level should either be 'private' or 'protected'.
// To minimize risk of a breach, we use private by default.
Storage.configure({ level: 'private' });

dayjs.extend(localizedFormat);
dayjs.extend(Calendar);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

function App() {
  const [user, setUser] = useState<MabelUser | undefined>();
  const [userRole, setUserRole] = useSessionStorageState<
    MabelUserRole | undefined
  >('userRole', undefined);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => setUser(new MabelUser(cognitoUser, userRole)))
      .catch((err) => {});

    const onAuthEvent: HubCallback = (data) => {
      if (
        data.payload.event === 'signIn' ||
        data.payload.event === 'tokenRefresh' ||
        data.payload.event === 'verify'
      ) {
        Auth.currentAuthenticatedUser()
          .then((cognitoUser) => setUser(new MabelUser(cognitoUser, userRole)))
          .catch((err) => {});
      } else {
        setUser(undefined);
      }
    };
    Hub.listen('auth', onAuthEvent);
    return () => Hub.remove('auth', onAuthEvent);
  }, [userRole]);

  const setRole = (role: MabelUserRole) => {
    if (user) {
      setUser(new MabelUser(user.cognito_user, role));
      setUserRole(role);
    }
  };
  return (
    <UserContext.Provider value={{ user: user, setRole: setRole }}>
      <AppHome />
    </UserContext.Provider>
  );
}

function AppHome() {
  const { user } = useContext(UserContext);
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_URL,
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map((graphQLError) => {
        const event_id = Sentry.captureMessage(graphQLError.message);
        Sentry.showReportDialog({
          eventId: event_id,
          user: {
            name: user?.claims?.name || undefined,
            email: user?.claims?.email || undefined,
          },
          labelComments:
            'What happened? (Please do not include PHI your description)',
        });
        notification.error({
          message: 'GraphQL Error',
          description: graphQLError.message,
          placement: 'bottomLeft',
          duration: 6,
        });
        return graphQLError;
      });
    }

    if (networkError) {
      Sentry.captureException(networkError);
      notification.error({
        message: 'Network Error',
        description: networkError.message,
        placement: 'bottomLeft',
        duration: 6,
      });
    }
  });
  const authLink = setContext(async (_, { headers }) => {
    const newHeaders = { ...headers };
    newHeaders['x-hasura-role'] = user?.role || 'public';
    if (user?.jwt) {
      // Calling Auth.currentSession() forces a token refresh if the current one expired
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      if (token) newHeaders.authorization = `Bearer ${token}`;
    }
    return { headers: newHeaders };
  });
  const sentryLink = new SentryLink({
    breadcrumb: {
      includeQuery: true,
      includeError: true,
    },
  });

  const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(sentryLink).concat(httpLink),
    connectToDevTools: process.env.NODE_ENV === 'development',
    cache: new InMemoryCache({
      dataIdFromObject: cacheIdFromObject,
    }),
  });

  return (
    <HelmetProvider>
      <MsalProvider instance={msalInstance}>
        <ApolloProvider client={client}>
          <Router>
            <AppWithRoutes />
          </Router>
        </ApolloProvider>
      </MsalProvider>
    </HelmetProvider>
  );
}

const AppWithRoutes = () => {
  usePageTracking();
  const { user } = useContext(UserContext);
  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/pdf-splitter">
        <PdfSplitterPage />
      </Route>
      <PrivateRoute path="/" user={user}>
        <ProtectedAppHome />
      </PrivateRoute>
    </Switch>
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
interface PrivateRouteProps extends RouteProps {
  user: MabelUser | undefined;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  user,
  children,
  ...rest
}) => {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function ProtectedAppHome() {
  const { user } = useContext(UserContext);
  const [, setUserRole] = useSessionStorageState('userRole', undefined);

  const client = useApolloClient();
  const signOut = () =>
    Auth.signOut().then(() => {
      setUserRole(undefined);
      client.clearStore();
    });

  const [, setLogoutTime] = useCountDown({
    onEnd: () => {
      Modal.destroyAll();
      signOut();
    },
  });

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    crossTab: {
      emitOnAllTabs: true,
      channelName: 'mabel-idle-timer',
      type: 'localStorage',
    },
    onIdle: () => {
      setLogoutTime(Date.now() + 60 * 1000);
      Modal.confirm({
        title: 'Your session is expiring',
        content: 'You will be logged out due to inactivity soon',
        okText: 'Stay Logged In',
        cancelText: 'Logout',
        onCancel: signOut,
        onOk: () => setLogoutTime(undefined),
      });
    },
    debounce: 500,
  });

  //Tell Sentry the user, for logging purposes
  Sentry.configureScope(function (scope) {
    scope.setUser({
      id: user?.user_id,
      email: user?.claims?.email || undefined,
      role: user?.role || undefined,
    });
  });

  // Cognito is the source of truth for user info.
  // Every time user logs in, we upsert their info to the DB
  // to ensure we're in sync
  const [upsertUser] = useUpsertUserMutation();
  useEffect(() => {
    (async () => {
      const credentials = await Credentials.get();
      const user_immutable: Partial<UserBasicFragment> = {
        user_id: user?.user_id,
        groups: user?.allowed_roles,
        identity_id: credentials.identityId || null,
        last_login_at: null /* forces automatic update via db trigger */,
      };
      upsertUser({
        variables: {
          user: {
            user_name: user?.claims?.name || '',
            user_email: user?.claims?.email,
            ...user_immutable,
          },
          cols_to_update: Object.keys(user_immutable) as Users_Update_Column[],
        },
      });
    })();
  }, [user, upsertUser]);

  const { height } = useWindowDimensions();

  return (
    <Layout>
      <MabelNavBar />
      <Layout.Content
        style={{
          padding: '24px 24px 0px',
          marginTop: 64,
          minHeight: height - 134, //header = 64, footer = 70
        }}
      >
        <div
          className="layout-page-background"
          style={{ padding: 24, minHeight: height - 134 - 48 }}
        >
          {user?.role === 'admin' ? (
            <Switch>
              <Route path="/templates">
                <TemplatesAdminPage />
              </Route>
              <Route path="/campaigns">
                <CampaignsAdminPage />
              </Route>
              <Route path="/campaign-manager/:campaign_template_id">
                <CampaignManagerPage />
              </Route>
              <Route path="/practices">
                <PracticesAdminPage />
              </Route>
              <Route path="/organizations">
                <OrganizationsAdminPage />
              </Route>
              <Route path="/networks">
                <NetworksAdminPage />
              </Route>
              <Route path="/users">
                <UsersAdminPage />
              </Route>
              <Route path="/providers">
                <ProvidersAdminPage />
              </Route>
              <Route path="/patients">
                <PatientsAdminPage />
              </Route>
              <Route path="/svas">
                <AlignmentProcessingPage />
              </Route>
              <Route path="/health-equity-reports">
                <HEDRProcessingPage />
              </Route>
              <Route path="/insurance-mapping">
                <InsuranceSegmentMappingPage />
              </Route>
              <Route exact path={['/settings', '/settings/:entity']}>
                <SettingsPage />
              </Route>
              <Redirect to="/practices" />
            </Switch>
          ) : user?.role === 'network_user' ? (
            <Switch>
              <Route path="/providers">
                <ProvidersPage />
              </Route>
              <Route exact path={['/settings', '/settings/:entity']}>
                <SettingsPage />
              </Route>
              <Redirect to="/providers" />
            </Switch>
          ) : user?.role === 'organization_user' ? (
            <Switch>
              <Route path="/dashboards">
                <OrganizationHomePage />
              </Route>
              <Route exact path={['/settings', '/settings/:entity']}>
                <SettingsPage />
              </Route>
              <Redirect to="/dashboards" />
            </Switch>
          ) : user?.role === 'practice_user' ? (
            <Switch>
              <Route exact path={['/']}>
                <PracticeHomePage />
              </Route>
              <Route exact path={['/onboarding', '/onboarding/:step']}>
                <OnboardingPage />
              </Route>
              <Route path="/patients">
                <PatientsPage />
              </Route>
              <Route path="/campaigns">
                <CampaignsPage />
              </Route>
              <Route path="/analytics">
                <PracticeAnalyticsPage />
              </Route>
              <Route exact path={['/settings', '/settings/:entity']}>
                <SettingsPage />
              </Route>
              <Redirect to="/" />
            </Switch>
          ) : (
            <Result title="User doesn't have a role assigned" status="error" />
          )}
        </div>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>
        {process.env.REACT_APP_WEBSITE_NAME} ©{new Date().getFullYear()}
      </Layout.Footer>
    </Layout>
  );
}

export default App;
