import { Auth } from 'aws-amplify';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const getMergeVars = async (
  component_type: 'letter' | 'email' | 'sms',
  component_id?: string,
  practice_id?: string,
  patient_id?: string | string[],
  template_id?: string
) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  // axios retry on timeout errors only
  axiosRetry(axios, {
    retries: 1,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (err) => {
      return err.response?.status === 504;
    },
  });
  const response = await axios.post(
    `${process.env.REACT_APP_CHALICE_URL}/compute_merge_vars`,
    {
      component_type: component_type,
      component_id: component_id,
      template_id: template_id,
      practice_id: practice_id,
      patient_id: patient_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const getComponentRecipients = async ({
  practice_id,
  patient_segment_id,
  send_datetime,
  campaign_template_id,
  limit,
  offset,
}: any) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  const response = await axios.get(
    `${process.env.REACT_APP_CHALICE_URL}/get_patients_in_segment/`,
    {
      params: {
        practice_id: practice_id,
        patient_segment_id: patient_segment_id,
        send_datetime: send_datetime,
        campaign_template_id: campaign_template_id,
        limit: limit,
        offset: offset,
      },
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

// getComponentRecipientCount is just like getComponentRecipient but returns only the count
const getComponentRecipientCount = async ({
  practice_id,
  patient_segment_id,
  send_datetime,
  campaign_template_id,
}: any) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  const response = await axios.get(
    `${process.env.REACT_APP_CHALICE_URL}/get_patients_in_segment_count/`,
    {
      params: {
        practice_id: practice_id,
        patient_segment_id: patient_segment_id,
        send_datetime: send_datetime,
        campaign_template_id: campaign_template_id,
      },
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

export { getMergeVars, getComponentRecipients, getComponentRecipientCount };
