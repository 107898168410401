import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Upload,
  Card,
  Row,
  Col,
  message,
  Typography,
  Spin,
  Divider,
} from 'antd';
import {
  InboxOutlined,
  FilePdfOutlined,
  DownloadOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist';
import { mabel_dark_blue } from '../colors';
import JSZip from 'jszip';

const { Title, Text } = Typography;
const { Dragger } = Upload;

pdfjsLib.GlobalWorkerOptions.workerSrc = '/static/js/pdf.worker.min.js';

const PdfSplitterPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [pageCount, setPageCount] = useState<number>(0);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [pageImages, setPageImages] = useState<string[]>([]);

  const handleFileUpload = async (file: File) => {
    setLoading(true);
    try {
      const fileBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(fileBuffer);
      const count = pdfDoc.getPageCount();
      setPageCount(count);
      setPdfFile(file);

      // Select all pages by default
      setSelectedPages(Array.from(Array(count).keys()));

      // Generate previews for all pages
      await generatePagePreviews(file, fileBuffer);
    } catch (error) {
      console.error('Error loading PDF:', error);
      message.error('Failed to load PDF file. Please try again.');
    } finally {
      setLoading(false);
    }
    return false; // Prevent default upload behavior
  };

  const generatePagePreviews = async (file: File, fileBuffer: ArrayBuffer) => {
    try {
      // Load the PDF using PDF.js
      const pdf = await pdfjsLib.getDocument({
        data: fileBuffer,
        isEvalSupported: false,
      }).promise;

      // Create an array of promises for each page rendering
      const renderPromises = Array.from(
        { length: pdf.numPages },
        async (_, i) => {
          try {
            const page = await pdf.getPage(i + 1);

            // Set the scale for the preview
            const viewport = page.getViewport({ scale: 0.5 });

            // Create a canvas to render the PDF page
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d', {
              willReadFrequently: true,
            });

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render the PDF page to the canvas
            await page.render({
              canvasContext: context!,
              viewport,
            }).promise;

            // Convert the canvas to a data URL
            return canvas.toDataURL('image/png');
          } catch (err) {
            console.error(`Error generating preview for page ${i + 1}:`, err);
            return ''; // Return empty string for failed previews
          }
        }
      );

      // Process all pages in parallel
      const images = await Promise.all(renderPromises);
      setPageImages(images);
    } catch (err) {
      console.error('Error loading PDF for previews:', err);
      setPageImages([]);
    }
  };

  const togglePageSelection = (pageNum: number) => {
    setSelectedPages((prev) =>
      prev.includes(pageNum)
        ? prev.filter((p) => p !== pageNum)
        : [...prev, pageNum].sort((a, b) => a - b)
    );
  };

  const splitPdf = async () => {
    if (!pdfFile || selectedPages.length === 0) {
      message.warning(
        'Please select a PDF file and at least one page to extract.'
      );
      return;
    }

    setLoading(true);
    try {
      const fileBuffer = await pdfFile.arrayBuffer();
      const srcPdfDoc = await PDFDocument.load(fileBuffer);
      const zip = new JSZip();

      // Create a separate PDF for each selected page and add to ZIP
      for (const pageIndex of selectedPages) {
        const newPdfDoc = await PDFDocument.create();
        const [copiedPage] = await newPdfDoc.copyPages(srcPdfDoc, [pageIndex]);
        newPdfDoc.addPage(copiedPage);

        // Save the new PDF
        const newPdfBytes = await newPdfDoc.save();
        const fileName = `${pdfFile.name.replace('.pdf', '')}_page_${
          pageIndex + 1
        }.pdf`;
        zip.file(fileName, newPdfBytes);
      }

      // Generate the ZIP file
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.download = `${pdfFile.name.replace('.pdf', '')}_pages.zip`;
      link.click();

      message.success('PDF split and zipped successfully!');
    } catch (error) {
      console.error('Error splitting PDF:', error);
      message.error('Failed to split PDF. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: mabel_dark_blue,
      }}
    >
      <Helmet>
        <title>PDF Splitter | Mabel</title>
        <meta
          name="description"
          content="Split PDF files into individual pages or selected pages."
        />
      </Helmet>
      <Card
        style={{
          width: 'calc(min(80%, 100% - 40px))',
          borderRadius: 8,
        }}
      >
        <Title level={2} style={{ textAlign: 'center' }}>
          PDF Splitter Tool
        </Title>
        <Text
          style={{ display: 'block', textAlign: 'center', marginBottom: 24 }}
        >
          Your files stay in your browser and are not uploaded to any server.
        </Text>

        <div style={{ display: pdfFile ? 'none' : 'block' }}>
          <Dragger
            accept=".pdf"
            beforeUpload={handleFileUpload}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag a PDF file to this area
            </p>
            {/* <p className="ant-upload-hint">
              Your files stay in your browser and are not uploaded to any
              server.
            </p> */}
          </Dragger>
        </div>

        {loading && (
          <div style={{ textAlign: 'center', margin: '24px 0' }}>
            <Spin size="large" />
            <p>Processing PDF...</p>
          </div>
        )}

        {pdfFile && pageCount > 0 && !loading && (
          <>
            <div style={{ margin: '24px 0' }}>
              <Title level={4}>
                <FilePdfOutlined /> {pdfFile.name} ({pageCount} pages)
              </Title>
              <Button
                type="link"
                onClick={() => {
                  setPdfFile(null);
                  setPageCount(0);
                  setSelectedPages([]);
                  setPageImages([]);
                }}
              >
                Change file
              </Button>
            </div>

            <Divider />

            <Title level={4}>Select pages to extract:</Title>
            <div
              style={{
                marginBottom: 16,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Button
                  type="link"
                  onClick={() =>
                    setSelectedPages(Array.from(Array(pageCount).keys()))
                  }
                >
                  Select All
                </Button>
                <Button
                  type="link"
                  icon={<CloseOutlined />}
                  onClick={() => setSelectedPages([])}
                >
                  Clear Selection
                </Button>
                <Text style={{ marginLeft: 16, marginRight: 16 }}>
                  {selectedPages.length} of {pageCount} pages selected
                </Text>
              </div>
              <Button
                type="primary"
                onClick={splitPdf}
                disabled={selectedPages.length === 0}
                icon={<DownloadOutlined />}
              >
                Extract {selectedPages.length}{' '}
                {selectedPages.length === 1 ? 'page' : 'pages'}
              </Button>
            </div>

            <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
              {Array.from({ length: pageCount }).map((_, index) => (
                <Col key={index} xs={12} sm={8} md={6}>
                  <Card
                    hoverable
                    size="small"
                    style={{
                      cursor: 'pointer',
                      border: selectedPages.includes(index)
                        ? `2px solid ${mabel_dark_blue}`
                        : '1px solid #d9d9d9',
                    }}
                    onClick={() => togglePageSelection(index)}
                    bodyStyle={{ padding: 8 }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      {pageImages[index] ? (
                        <div
                          style={{
                            height: 150,
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {/* Replace object tag with img tag for preview */}
                          <img
                            src={pageImages[index]}
                            alt={`Page ${index + 1}`}
                            style={{
                              maxHeight: 140,
                              maxWidth: '100%',
                              border: '1px solid #eee',
                            }}
                            onError={(e) => {
                              // Handle image loading error
                              e.currentTarget.src = '/pdf-placeholder.png';
                              console.error(
                                `Error loading preview for page ${index + 1}`
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            height: 150,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#f0f0f0',
                          }}
                        >
                          <FilePdfOutlined
                            style={{ fontSize: 40, opacity: 0.5 }}
                          />
                        </div>
                      )}
                      <div style={{ marginTop: 8 }}>
                        <Text strong>Page {index + 1}</Text>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Card>
    </div>
  );
};

export default PdfSplitterPage;
